.wlx-header-logo {
  max-height: 48px;
}

/*.wlx-navbar-btn {
  border: none;
  background-color: transparent;
}
.wlx-navbar-btn:focus {
  outline:0;
}
.wlx-view-container {
  background-color: white;
  padding: 15px;
}*/

a {
  color: #0d92b4 !important;
}
a.decorate-link:hover {
  text-decoration: underline;
  cursor: pointer;
}
.page-item.active .page-link {
  color: #fff !important;
}

.dark-bg {
  background-image: linear-gradient(#0d92b4, #0d92b4);
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.login-logo {
  /*height: 48px;*/
  width: 170px;
}

.w-7 {
  width: 3.5rem;
}
.w-8 {
  width: 4rem;
}
.h-7 {
  height: 3.5rem;
}
.h-8 {
  height: 4rem;
}

input[type="checkbox"]:focus,
input[type="radio"]:focus {
  border-color: #0d92b4 !important;
}

input[type="checkbox"]:hover,
input[type="radio"]:hover {
  border-color: #0d92b4 !important;
}

.form-control-blue:focus {
  box-shadow: 0 0 0 2px rgb(13 146 180 / 20%);
  border-color: #0d92b4 !important;
}

.form-control:focus {
  box-shadow: 0 0 0 2px rgb(137 110 177 / 20%);
  color: #696969;
  border-color: #896eb1;
  background-color: #fff;
  outline: 0;
}

.sidebar-light .nav > .nav-item > .nav-link:hover {
  background-color: #0d92b4 !important;
  color: #fff !important;
}

.sidebar-light .nav > .nav-item > .nav-link:hover > .nav-icon {
  background-color: #0d92b4 !important;
  color: #fff !important;
}

.sidebar-light .nav > .nav-item > .nav-link:hover > .nav-text {
  background-color: #0d92b4 !important;
  color: #fff !important;
}

.navbar-blue,
.navbar-blue .navbar-intro {
  background-color: #0d92b4;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #0d92b4;
  color: #fff !important;
}

.dropdown-item:not(.btn):focus,
.dropdown-item:not(.btn):hover,
.dropdown .btn-h-primary:hover {
  background-color: #c6b9d9;
}
.dropdown .btn-h-primary a,
a.dropdown-item {
  color: #896eb1 !important;
}
a.dropdown-item.selected {
  background-color: #896eb1;
  color: #ffffff !important;
}

.dropdown-item:not(.btn):focus,
.dropdown-item:not(.btn):hover,
.dropdown .btn-h-primary a:hover {
  color: #ffffff !important;
}
.btn-a-primary:not(:disabled):not(.disabled).active,
.btn-a-primary:not(:disabled):not(.disabled):active,
.show > .btn.btn-a-primary.dropdown-toggle {
  background-color: #896eb1;
  color: #ffffff !important;
}

.btn-outline-default:not(:disabled):not(.disabled).active,
.btn-outline-default:not(:disabled):not(.disabled):active,
.show > .btn.btn-outline-default.dropdown-toggle {
  color: #ffffff !important;
}

.swal-button--delete,
.swal-button--confirm,
.swal-button--stay {
  color: #ffffff !important;
  background-color: #c6b9d9 !important;
  border-color: #c6b9d9 !important;
}

.btn-outline-default:hover {
  color: #ffffff !important;
}

.btn-outline-default.active {
  color: #ffffff !important;
}

.btn-outline-default {
  color: #0d92b4 !important;
}

.page-item.active .page-link {
  color: #ffffff !important;
  background-color: #4188b3 !important;
  border-color: #4188b3 !important;
}

.page-link:hover {
  color: #ffffff !important;
  background-color: #4188b3 !important;
  border-color: #4188b3 !important;
}

.page-link {
  color: #0d92b4 !important;
}

div.inner-brc-h-purp-l1:hover > div:first-child {
  background-color: #f1eef6 !important;
  border-color: #896eb1 !important;
}

div.inner-brc-h-purp-l1:hover > div:last-child {
  /*background-color: #f1eef6 !important;*/
  border-color: #896eb1 !important;
}

.brc-h-purp-l1:hover {
  background-color: #f1eef6 !important;
  border-color: #896eb1 !important;
}

.brc-purp-l1 {
  background-color: #f1eef6 !important;
  border-color: #896eb1 !important;
}

.brc-teal-l1 {
  background-color: #f3ffff !important;
  border-color: #00a8a7 !important;
}

.text-teal {
  color: #00a8a7 !important;
}

.text-purp {
  color: #896eb1 !important;
}

.page-title {
  font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
}

.card-title {
  font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
  font-weight: bold;
}

.sidebar-light {
  font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
}

.sidebar-light .nav.has-active-border .nav-item.active > .nav-link::before {
  border-color: #0d92b4;
}
.sidebar-light.collapsed .nav > .nav-item.active {
  border-top-width: 0px;
  border-right-width: 2px;
  border-bottom-width: 4px;
  border-bottom-color: #0d92b4;
  background-color: transparent;
}
.sidebar-light.collapsed
  .nav.has-active-border
  .nav-item.active
  > .nav-link::before {
  border-color: transparent;
}
.sidebar-light .nav > .nav-item.active > .nav-link > .nav-icon {
  color: #0d92b4;
}

.sidebar-light .nav > .nav-item > .nav-link > .nav-text {
  color: #0d92b4 !important;
}

.btn-primary,
.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #0d92b4;
  border-color: #0d92b4;
}
.btn-lighter-primary {
  color: #0d92b4;
}
.btn-purple {
  background-color: #896eb1;
  color: #ffffff !important;
}

.text-primary {
  color: #0d92b4 !important;
}
.text-primary-d2 {
  color: #0d92b4 !important;
}

.badge-primary {
  background-color: #0d92b4;
}
.bgc-primary {
  background-color: #0d92b4 !important;
}
.brc-primary-m1 {
  border-color: #0d92b4 !important;
}
.brc-primary-tp3 {
  border-color: #0d92b4 !important;
}
.nav-tabs .nav-item.show .nav-link:not([class*="btn-"]),
.nav-tabs .nav-link.active:not([class*="btn-"]) {
  border-top-color: #0d92b4 !important;
}
.bgc-search-purple {
  background-color: #62619e !important;
}
.bgc-purple {
  background-color: #896eb1 !important;
}
.bgc-purple-l3 {
  background-color: #c6b9d9 !important;
}
.bgc-teal {
  background-color: #00a8a7 !important;
}

.nav-tabs .nav-item.show .nav-link:not([class*="btn-"]),
.nav-tabs .nav-link.border-t-c-purple.active:not([class*="btn-"]) {
  border-top-color: #896eb1 !important;
}

.profile-bubble-xxs {
  border-radius: 20px;
  width: 40px;
  height: 40px;

  /*border: 2px solid #fff;
  box-shadow: -2px 0 1rem rgba(0, 0, 0, .1);*/
  display: inline-block;
}

.profile-bubble-xs {
  border-radius: 25px;
  width: 50px;
  height: 50px;
  /*border: 2px solid #fff;
  box-shadow: -2px 0 1rem rgba(0, 0, 0, .1);*/
  margin: 0;
  display: inline-block;
}

.profile-bubble-sm {
  border-radius: 40px;
  width: 80px;
  height: 80px;
  /*border: 2px solid #fff;
  box-shadow: -2px 0 1rem rgba(0, 0, 0, .1);*/
  margin: 0;
  display: inline-block;
}

.profile-bubble {
  border-radius: 80px;
  width: 125px;
  height: 125px;

  /*border: 2px solid #fff;
  box-shadow: -2px 0 1rem rgba(0, 0, 0, .1);*/
  margin: 0;
  display: inline-block;
}

.pointer {
  cursor: pointer;
}

.scroll-h-fixed-xlg {
  height: 300px;
  overflow-y: auto;
}

.scroll-h-fixed-lg {
  height: 250px;
  overflow-y: auto;
}

.scroll-h-600 {
  max-height: 600px;
  overflow-y: auto;
}
.scroll-h-500 {
  max-height: 500px;
  overflow-y: auto;
}
.scroll-h-xxxl {
  max-height: 400px;
  overflow-y: auto;
}
.scroll-h-xxl {
  max-height: 350px;
  overflow-y: auto;
}
.scroll-h-xl {
  max-height: 300px;
  overflow-y: auto;
}
.scroll-h-lg {
  max-height: 250px;
  overflow-y: auto;
}
.scroll-h-md {
  max-height: 200px;
  overflow-y: auto;
}
.scroll-h-sm {
  max-height: 150px;
  overflow-y: auto;
}
.scroll-h-xs {
  max-height: 100px;
  overflow-y: auto;
}
.scroll-h-xxs {
  max-height: 50px;
  overflow-y: auto;
}

.white-space-pre-line {
  white-space: pre-line;
}

#home-search-results-tab,
#home-pinned-objects-tab {
  max-height: calc(100vh - 150px) !important;
  min-height: 265px;
  overflow-y: auto;
}

div.inner-ace-select {
  border-radius: 3px;
}

div.inner-brc-default-m3 > div {
  border: 2px solid #a2c5da !important;
  box-shadow: none;
}

div.inner-bgc-h-warning-l3:hover > div:first-child {
  background-color: #fcf2e0 !important;
}

div.inner-brc-h-warning-m1:hover > div:first-child {
  border-color: #f0b34f !important;
}

.ml-n6 {
  margin-left: -4rem !important;
}
.ml-n7 {
  margin-left: -5rem !important;
}

.dr-cals {
  border-top-color: #0d92b4;
}

.text-115 {
  font-size: 1.15em !important;
}

.no-sort::after {
  display: none !important;
}
.no-sort {
  pointer-events: none !important;
  cursor: default !important;
}

.chart-center canvas {
  margin: auto;
}

.Toastify__toast-body {
  /*font-size: 0.9em !important;
  color: #28292c;*/
  font-family: "Open Sans", Arial, sans-serif;
}

.Toastify__progress-bar--default {
  background: none;
  background-color: #62619e;
  /*background-color: #0d92b4;*/
}

input[type="file"] {
  font-size: 0.9rem;
}

input[type="file"]::file-selector-button {
  background-color: #896eb1;
  border: none;
  color: #ffffff !important;
  padding: 0.1em 0.5em;
  margin-right: 0.5em;
  border-radius: 0.2em;
  vertical-align: top;
}
input[type="file"]::file-selector-button:hover {
  background-color: #6b60ab;
  border: none;
}

.dataTable tr.child ul.dtr-details > li > .dtr-title {
  width: 200px;
  text-align: right;
  text-overflow: initial;
  white-space: normal;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.input-required:after {
  position: relative;
  z-index: 10;
}
.input-required:after,
.tab-error::after {
  content: " *";
  color: red;
}

.dp {
  z-index: 1050 !important;
  border-top: 0.25rem solid #0d92b4;
}

.dr-cals .dr-in-range,
.dr-cals .dr-in-range:focus,
.dr-cals .dr-in-range:hover {
  background-color: #f1eef6;
}

.dr-cals .dr-selected,
.dr-cals .dr-selected:focus,
.dr-cals .dr-selected:hover {
  background: #896eb1;
}

.dp-current,
.dp-current:focus,
.dp-day:focus,
.dp-selected,
.dp-selected:focus,
.dp-selected:hover,
.dp-year:focus {
  background: #896eb1;
  color: #fff;
}

.dataTables_wrapper > .row.datatable-length-filter-cntr {
  padding: 1.25rem 0.5rem 0.75rem;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  background-color: #f6f9fb;
}

.text-vacant {
  color: #896eb1 !important;
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}

/* 2.0 changes */
.btn-a-purple:not(:disabled):not(.disabled).active, 
.btn-a-purple:not(:disabled):not(.disabled):active, 
.show>.btn.btn-a-purple.dropdown-toggle {
  color: #fff!important;
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    /*
      Define here the CSS styles applied only to Safari browsers
      (any version and any device)
     */
    .form-control[disabled] {
      color: #111 !important;
    }
  }
}

/* issue with ff no showing search controls full width using d-table class */
@-moz-document url-prefix() {
  .search-control .d-table:not(.disabled) {
    width: calc(100% - 41px);
  }
  .search-control.search-control-has-clear .d-table:not(.disabled) {
    width: calc(100% - 76px);
  }
  .search-control .d-table.disabled {
    width: 100%;
  }
}

.brc-pink {
  border-color: #ce158b!important;
}
.brc-teal {
  border-color: #26ace2!important;
}
.brc-gold {
  border-color: #deb932!important;
}
.brc-orange {
  border-color: #f36d38!important;
}

.brc-green-l1 {
  background-color: #e7f6e3 !important;
  border-color: #64b94a !important;
}
.brc-yellow-l1 {
  background-color: #f7f0d3 !important;
  border-color: #d5ac27 !important;
}
.brc-red-l1 {
  background-color: #f8e4e5 !important;
  border-color: #be1f27 !important;
}

.editor-control {
  border: 1px solid #d3d5d7;
}